import { SessionResponse } from "../contexts/authContext";

interface InternalSessionProps extends SessionResponse {
  loginDate: string;
}

export const LOCAL_STORAGE_SESSION_KEY = "@zatten-wl:session";
const expirationTimeInHours = 24 * 7;

const getDifferenceInHours = (date1: Date, date2: Date) => {
  const diff = date1.getTime() - date2.getTime();
  return diff / 3600000;
};

export const getSavedSession = () => {
  const savedSession = localStorage.getItem(LOCAL_STORAGE_SESSION_KEY);
  const parsedSession = savedSession
    ? (JSON.parse(savedSession) as InternalSessionProps)
    : undefined;

  if (!parsedSession) return undefined;

  const loginDate = new Date(parsedSession.loginDate);
  const now = new Date();

  const diff = getDifferenceInHours(now, loginDate);

  if (diff > expirationTimeInHours) {
    localStorage.removeItem(LOCAL_STORAGE_SESSION_KEY);
    return undefined;
  }

  return parsedSession as SessionResponse;
};

export const saveSession = (session: SessionResponse) => {
  const loginDate = new Date();
  const internalSession: InternalSessionProps = {
    ...session,
    loginDate: loginDate.toISOString(),
  };
  localStorage.setItem(
    LOCAL_STORAGE_SESSION_KEY,
    JSON.stringify(internalSession)
  );
};
