import { Button } from "@/components/ui/Button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/Dialog";
import { toast } from "react-toastify";
import { useAuth } from "@/contexts/authContext";
import api from "@/services/api";
import { Power, PowerOff, Trash } from "lucide-react";
import React, { useState } from "react";
import {
  AlertDialog,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogTrigger,
  AlertDialogTitle,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogCancel,
  AlertDialogAction,
} from "@/components/ui/AlertDialog";

const ConnectionButton: React.FC = () => {
  const [connectAlert, setConnectAlert] = useState(false);
  const { getClientData, client } = useAuth();
  const [openDisconnect, setOpenDisconnect] = useState(false);
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState({
    code: "",
    qr: "",
  });

  const disconnect = async () => {
    await api.delete(`/disconnect-whatsapp/${client?.phoneId}`);
    await getClientData();
  };

  const connect = async () => {
    setLoading(true);
    setConnectAlert(true);

    try {
      const { data } = await api.post<{ code: string; qr: string }>(
        `/connect-whatsapp/${client?.phoneId}`
      );

      setCode(data);
    } catch (error) {
      toast.error("Erro ao conectar. Tente novamente mais tarde");
    } finally {
      setLoading(false);
    }
  };

  const onCloseConnectModal = () => {
    setConnectAlert(false);
    getClientData();
  };

  const ConnectModal = () => {
    return (
      <Dialog
        open={connectAlert}
        onOpenChange={(open) => {
          setConnectAlert(open);
          if (!open) {
            getClientData();
          }
        }}
      >
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>Conectar atendente</DialogTitle>
          </DialogHeader>
          <div>
            {loading && <p className="text-center text-3xl">Carregando...</p>}

            {!loading && code.code && (
              <>
                <p className="text-center text-md  mt-4">Código de acesso:</p>
                <p className="text-center text-primary font-extrabold text-xl tracking-large mt-2">
                  {code.code.replace(/^(.{4})/, "$1-")}
                </p>
              </>
            )}

            {!loading && code.qr && (
              <>
                <p className="text-center text-md mt-8">QR code:</p>
                <img
                  className="w-60 h-60 object-contain mx-auto mt-4 rounded-lg bg-white p-0"
                  src={code.qr}
                  alt="QR Code"
                />
              </>
            )}
          </div>

          <DialogFooter className="mt-6">
            <Button
              disabled={loading}
              className="flex flex-1 bg-background"
              variant="ghost"
              onClick={connect}
            >
              Gerar novo código
            </Button>
            <Button
              className="flex flex-1"
              onClick={onCloseConnectModal}
              disabled={loading}
            >
              Já conectei
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    );
  };

  const DisconnectModal = () => {
    return (
      <AlertDialog onOpenChange={setOpenDisconnect} open={openDisconnect}>
        <AlertDialogTrigger>
          <Button
            className="flex flex-1 bg-red"
            onClick={() => setOpenDisconnect(true)}
          >
            Desconectar
            <PowerOff size={16} className="ml-2" />
          </Button>
        </AlertDialogTrigger>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>
              Tem certeza que deseja desconectar o WhatsApp?
            </AlertDialogTitle>
            <AlertDialogDescription>
              Essa ação desligará o seu robô para todas as conversas. Você
              poderá conectar novamente depois.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel
              onClick={() => disconnect()}
              className="text-red"
            >
              Desconectar
            </AlertDialogCancel>
            <AlertDialogAction onClick={() => setOpenDisconnect(false)}>
              Cancelar
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    );
  };

  return (
    <>
      {!client?.isConnected && (
        <Button className="flex flex-1" onClick={connect}>
          Conectar atendente
          <Power size={16} className="ml-2" />
        </Button>
      )}
      {client?.isConnected && <DisconnectModal />}

      <ConnectModal />
    </>
  );
};

export default ConnectionButton;
